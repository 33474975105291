import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    default: {
      palette: {
        main: React.CSSProperties['color']
        light: React.CSSProperties['color']
        gradient: React.CSSProperties['color']
        extralight: React.CSSProperties['color']
        dark: React.CSSProperties['color']
        richblack: React.CSSProperties['color']
        coolblack: React.CSSProperties['color']
        granitegrey: React.CSSProperties['color']
        coolgrey: React.CSSProperties['color']
        platinumgrey: React.CSSProperties['color']
        cloudygrey: React.CSSProperties['color']
        white: React.CSSProperties['color']
        yellow: React.CSSProperties['color']
        yellowshade: React.CSSProperties['color']
        mandarin: React.CSSProperties['color']
        mandarinshade: React.CSSProperties['color']
        orange: React.CSSProperties['color']
        orangeshade: React.CSSProperties['color']
        red: React.CSSProperties['color']
        redshade: React.CSSProperties['color']
        indigo: React.CSSProperties['color']
        indigoshade: React.CSSProperties['color']
        blue: React.CSSProperties['color']
        blueshade: React.CSSProperties['color']
        turquoise: React.CSSProperties['color']
        turquoiseshade: React.CSSProperties['color']
        green: React.CSSProperties['color']
        greenshade: React.CSSProperties['color']
        facebook: React.CSSProperties['color']
        darkpurple: React.CSSProperties['color']
        crpurple: React.CSSProperties['color']
        lilac: React.CSSProperties['color']
        magnolia: React.CSSProperties['color']
      }
    }
    mme: {
      palette: {
        main: React.CSSProperties['color']
        gradient: React.CSSProperties['color']
        link: React.CSSProperties['color']
        green: React.CSSProperties['color']
      }
    }
  }
  interface ThemeOptions {
    default: {
      palette: {
        main: React.CSSProperties['color']
        light: React.CSSProperties['color']
        gradient: React.CSSProperties['color']
        extralight: React.CSSProperties['color']
        dark: React.CSSProperties['color']
        richblack: React.CSSProperties['color']
        coolblack: React.CSSProperties['color']
        granitegrey: React.CSSProperties['color']
        coolgrey: React.CSSProperties['color']
        platinumgrey: React.CSSProperties['color']
        cloudygrey: React.CSSProperties['color']
        white: React.CSSProperties['color']
        yellow: React.CSSProperties['color']
        yellowshade: React.CSSProperties['color']
        mandarin: React.CSSProperties['color']
        mandarinshade: React.CSSProperties['color']
        orange: React.CSSProperties['color']
        orangeshade: React.CSSProperties['color']
        red: React.CSSProperties['color']
        redshade: React.CSSProperties['color']
        indigo: React.CSSProperties['color']
        indigoshade: React.CSSProperties['color']
        blue: React.CSSProperties['color']
        blueshade: React.CSSProperties['color']
        turquoise: React.CSSProperties['color']
        turquoiseshade: React.CSSProperties['color']
        green: React.CSSProperties['color']
        greenshade: React.CSSProperties['color']
        facebook: React.CSSProperties['color']
        darkpurple: React.CSSProperties['color']
        crpurple: React.CSSProperties['color']
        lilac: React.CSSProperties['color']
        magnolia: React.CSSProperties['color']
      }
    }
    mme: {
      palette: {
        main: React.CSSProperties['color']
        gradient: React.CSSProperties['color']
        headerBg: React.CSSProperties['color']
        link: React.CSSProperties['color']
        green: React.CSSProperties['color']
      }
    }
  }
}

export const theme = createMuiTheme({
  default: {
    palette: {
      main: '#8700d7',
      light: '#BB68D4',
      dark: '#500080',
      gradient: 'radial-gradient(60.31% 601.9% at 39.69% 50%, #4200FF 0%, #8700D7 100%)',
      extralight: '#F9F2FD',
      richblack: '#000000',
      coolblack: '#282828',
      granitegrey: '#666666',
      coolgrey: '#BDBDBD',
      platinumgrey: '#E0E0E0',
      cloudygrey: '#EEEEEE',
      white: '#FFFFFF',
      yellow: '#FFE600',
      yellowshade: '#FFE600',
      mandarin: '#FF9900',
      mandarinshade: '#FF9900',
      orange: '#FE5D26',
      orangeshade: '#FE5D26',
      red: '#EB001B',
      redshade: '#EB001B',
      indigo: '#3726B0',
      indigoshade: '#3726B0',
      blue: '#0050FF',
      blueshade: '#0050FF',
      turquoise: '#0ED3C4',
      turquoiseshade: '#0ED3C4',
      green: '#0FA958',
      greenshade: '#0FA958',
      facebook: '#3B5998',
      darkpurple: '#500080',
      crpurple: '#8700D7',
      lilac: '#BB68D4',
      magnolia: '#F9F2FD',
    },
  },
  mme: {
    palette: {
      main: '',
      gradient:
        'linear-gradient(180.49deg, #004B4B -33.92%, #186363 -33.91%, #005E5C 0.39%, rgba(0, 114, 114, 0) 99.56%, rgba(7, 213, 213, 0) 99.57%), linear-gradient(90deg, #00CDCA 2.13%, #00D5BE 56.27%, #00EBA0 100%);',
      headerBg: '#B9EB00',
      link: '#000000',
      green: '#BBEE00',
    },
  },
  palette: {
    primary: {
      main: '#8700d7',
    },
    secondary: {
      main: '#BB68D4',
    },
    background: {
      default: '#FFFFFF',
    },
    action: {
      disabledBackground: '#E0E0E0',
      disabled: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 10,
      },
      label: {
        position: 'relative',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#E3E3E3',
        '&$checked': {
          color: '#8700D7',
        },
        '&$focused': {
          color: '#8700D7',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#282828',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '140%',
        paddingBottom: 4,
      },
    },
    MuiInputBase: {
      root: {
        height: 48,
        color: '#666666',
        //hide up/down arrow for number input fields
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
          margin: 0,
        },
        '&$error': {
          color: '#F14A86',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        lineHeight: '140%',
        '&$error': {
          color: '#F14A86',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$error $notchedOutline': {
          borderColor: '#F14A86!important',
        },
        '&$focused $notchedOutline': {
          border: '1px solid #8700D7 !important',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#8700D7',
        },
      },
      notchedOutline: {
        borderColor: '#E3E3E3',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTypography: {
      body2: {
        color: '#666666',
      },
      subtitle1: {
        color: '#666666',
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
})
